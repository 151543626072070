import React, { FC, useEffect, useState } from 'react';
import { HttpClient, WidgetProps } from '@wix/yoshi-flow-editor';
import {
  Button,
  Divider,
  Image,
  ImageResizeOptions,
  Text,
  TextPriority,
  ButtonPriority,
  TextTypography,
  Dialog,
} from 'wix-ui-tpa';
import {
  deleteProduct,
  getProduct,
} from '@wix/ambassador-bazaar-v1-product/build/cjs/http';
import { Product } from '@wix/ambassador-bazaar-v1-product/build/cjs/types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  productId: string;
  loggedInMember: any;
  instance: string;
  navigateToEdit: () => any;
  navigateToFeed: () => any;
};
const BLANK_IMAGE =
  'https://raw.githubusercontent.com/julien-gargot/images-placeholder/master/placeholder-landscape.png';

const InfoStack = ({ description, value }: any) => {
  return (
    <div className={st(classes.infoStack)}>
      <Text priority={TextPriority.secondary}>{description}</Text>
      <Text>{value}</Text>
    </div>
  );
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  productId,
  instance,
  loggedInMember,
  navigateToEdit,
  navigateToFeed,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [data, setData] = useState<Product>({ images: [] });
  const [member, setMember] = useState({} as any);
  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = getProduct({ productId })({
        isSSR: false,
        host: 'NA',
      });
      const httpClient = new HttpClient({
        baseURL: '/_api/bazaar/',
        headers: { Authorization: instance },
      });
      const prod: any = await httpClient.request(requestOptions);
      setData(prod.data.product);
      setSelectedImage(prod.data.product.images[0] || { url: BLANK_IMAGE });
      const members = MembersNgApi('/_api/members').Members()({
        Authorization: instance,
      });
      const memberData = await members.getMember({
        id: prod.data.product.memberId,
      });
      if (memberData.member) {
        console.log(memberData.member.profile);
        setMember({
          ...memberData.member.profile,
          createdDate: memberData.member.createdDate,
        });
      }
    };

    fetchData();
  }, []); // ignore
  const [selectedImage, setSelectedImage] = useState(
    data.images?.[0] || { url: BLANK_IMAGE },
  );
  const deleteListing = async () => {
    try {
      const requestOptions = deleteProduct({
        productId,
        revision: data.revision as any,
      })({
        isSSR: false,
        host: 'NA',
      });
      const httpClient = new HttpClient({
        baseURL: '/_api/bazaar/',
        headers: { Authorization: instance },
      });
      await httpClient.request(requestOptions);
      setIsDeleteDialogOpen(false);
      navigateToFeed();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={st(classes.root, {})} data-hook="Product-wrapper">
      <div className={st(classes.rootContainer)}>
        <div className={st(classes.header)}>
          <Text
            className={st(classes.title)}
            typography={TextTypography.largeTitle}
          >
            {data.name}
          </Text>
          {(loggedInMember?.member?.profile?.slug && member?.slug && loggedInMember.member.profile.slug === member.slug) && (
            <div className={st(classes.buttonsContainer)}>
              <Button
                className={st(classes.editButton)}
                upgrade
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                Delete Listing
              </Button>
              <Dialog
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
              >
                <div className="content" style={{ textAlign: 'center' }}>
                  <Text typography={TextTypography.smallTitle}>
                    Are you sure?
                  </Text>
                  <div
                    className="text-container"
                    style={{ marginTop: '24px', marginBottom: '36px' }}
                  >
                    <Text typography={TextTypography.listText} tagName="div">
                      <div>Do you really want to delete this listing?</div>
                      <div>Once removed, cannot be undone.</div>
                    </Text>
                  </div>
                  <Button
                    upgrade
                    onClick={() => setIsDeleteDialogOpen(true)}
                    priority={ButtonPriority.basicSecondary}
                    style={{ marginLeft: '10px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => deleteListing()}
                    upgrade
                    style={{ marginLeft: '10px' }}
                  >
                    Delete
                  </Button>
                </div>
              </Dialog>

              <Button
                onClick={() => navigateToEdit()}
                className={st(classes.editButton)}
                upgrade
                priority={ButtonPriority.primary}
              >
                Edit listing
              </Button>
            </div>
          )}
        </div>
        <div className={st(classes.mainContentContainer)}>
          <div
            className={st(classes.sideContentContainer, classes.imageContainer)}
          >
            <Image
              className={st(classes.imageContainer)}
              resize={ImageResizeOptions.contain}
              src={selectedImage?.url}
            />
            <div className={st(classes.imagesPreviewContainer)}>
              {data.images?.map(({ url }, index) => (
                <div
                  key={`${index}_${url}`}
                  className={st(classes.click)}
                  onClick={() => setSelectedImage({ url })}
                >
                  <Image className={st(classes.imagePreview)} src={url} />
                </div>
              ))}
            </div>
            <div className={st(classes.descriptionContainer)}>
              <Text
                typography={TextTypography.smallTitle}
                className={st(classes.description)}
              >
                Description:
              </Text>
              <Text>{data.description}</Text>
            </div>
          </div>
          <div className={st(classes.sideContentContainer)}>
            <div className={st(classes.infoContainer)}>
              <InfoStack
                description="Upload date:"
                value={data.createdDate?.toLocaleDateString('en-US')}
              />
              <InfoStack
                description="Condition:"
                value={data.condition === 'NEW' ? 'New' : 'Used'}
              />
              <InfoStack description="Delivery:" value={data.delivery} />
              <InfoStack
                description="Location:"
                value={
                  (data.location as any)?.charAt(0)?.toUpperCase() +
                  (data.location as any)?.slice(1)
                }
              />
              <InfoStack
                description="Status:"
                value={data.available === '1' ? 'Available' : 'Not Available'}
              />
              <Divider className={st(classes.divider)} />
              <InfoStack
                description="Price:"
                value={
                  Number(data.price?.value)
                    ? `${data.price?.currency} ${data.price?.value}`
                    : 'Free'
                }
              />
            </div>
            <div
              className={st(classes.infoContainer, classes.profileContainer)}
            >
              <div className={st(classes.profileData)}>
                <div style={{ borderRadius: '50%' }}>
                  <Image
                    className={st(classes.profileImage)}
                    src={(member as any)?.photo?.url || BLANK_IMAGE}
                  />
                </div>
                <div className={st(classes.profileDescription)}>
                  <Text>{(member as any).nickname}</Text>
                  <Text
                    className={st(classes.smallText)}
                    priority={TextPriority.secondary}
                  >
                    Join date:{' '}
                    {(member as any).createdDate?.toLocaleDateString('en-US')}
                  </Text>
                </div>
              </div>
              <Text className={st(classes.smallText)}>
                Please contact the seller via slack to arrange the deal.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
